import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider, SurveyProvider, PageTitleProvider } from './utils/context'
import GlobalStyle from './utils/style/GlobalStyle'
import { Loader } from './utils/style/Atoms'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Suspense, lazy } from 'react';
import SkipLinks from './components/SkipLinks'
import Survey from './pages/Survey/Survey'
import Home from './pages/Home/Home'

const Header = lazy(() =>
  import(
    /* webpackChunkName: "header" */ /* webpackPreload: true */ './components/Header'
  )
);

const Footer = lazy(() =>
  import(
    /* webpackChunkName: "footer" */ /* webpackPrefetch: true */ './components/Footer'
  )
);

const CGV = lazy(() =>
  import(
    /* webpackChunkName: "cgv" */ /* webpackPrefetch: true */ './pages/CGV/CGV'
  )
);

const Mentions = lazy(() =>
  import(
    /* webpackChunkName: "mentions" */ /* webpackPrefetch: true */ './pages/Mentions/Mentions'
  )
);

const Policies = lazy(() =>
  import(
    /* webpackChunkName: "policies" */ /* webpackPreload: true */ './pages/ConfidentialPolicy/ConfidentialPolicy'
  )
);

const Sitemap = lazy(() =>
  import(
    /* webpackChunkName: "sitemap" */ /* webpackPrefetch: true */ './pages/Sitemap/sitemap.xml'
  )
);


const SiteCreation = lazy(() =>
  import(
    /* webpackChunkName: "siteCreation" */ /* webpackPreload: true */ './pages/SiteCreation/SiteCreation'
  )
);

const Applications = lazy(() =>
  import(
    /* webpackChunkName: "application" */ /* webpackPreload: true */ './pages/Applications/Applications'
  )
);

const SEO = lazy(() =>
  import(
    /* webpackChunkName: "seo" */ /* webpackPreload: true */ './pages/SEO/Seo'
  )
);

const WebDevelopment = lazy(() =>
  import(
    /* webpackChunkName: "webdev" */ /* webpackPreload: true */ './pages/WebDevelopment/WebDevelopment'
  )
);


const Specialites = lazy(() =>
  import(
    /* webpackChunkName: "specialites" */ /* webpackPreload: true */ './pages/Specialites/Specialites'
  )
);

const Expertise = lazy(() =>
  import(
    /* webpackChunkName: "expertise" */ /* webpackPreload: true */ './pages/Expertise/Expertise'
  )
);

const Error = lazy(() =>
  import(
    /* webpackChunkName: "error" */ /* webpackPreload: true */ './components/Error'
  )
);

const Results = lazy(() =>
  import(
    /* webpackChunkName: "results" */ /* webpackPreload: true */ './pages/Results/Results'
  )
);

const Team = lazy(() =>
  import(
    /* webpackChunkName: "team" */ /* webpackPreload: true */ './pages/WhoWeAre/WhoWeAre'
  )
);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  return (
    <React.StrictMode>
      <HelmetProvider>
        <Suspense fallback={<main style={{display:'flex', textAlign:'center', justifyContent:'center', height:'100vh', alignItems:'center'}}><Loader theme='dark' /></main>}>
          <Router
            future={{
              v7_startTransition: true,
              v7_relativeSplatPath: true,
            }}>
            <ScrollToTop />
            <ThemeProvider>
              <SurveyProvider>
                  <GlobalStyle />
                  <PageTitleProvider>
                    <SkipLinks />
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/creation-sites-internet-caen" element={<SiteCreation />} />
                        <Route path="/developpeur-application-mobile-web-caen" element={<Applications />} />
                        <Route path="/referencement-seo" element={<SEO />} />
                        <Route path="/developpement-web-android" element={<WebDevelopment />} />
                        <Route path="/votre-projet/:questionNumber" element={<Survey />} />
                        <Route path="/resultats" element={<Results />} />
                        <Route path="/entreprise-developpement-web-caen" element={<Team />} />
                        <Route path="/expert-conception-web-mobile-caen" element={<Expertise />} />
                        <Route path="/competences-certifications" element={<Specialites />} />
                        <Route path="/mentions-legales" element={<Mentions />} />
                        <Route path="/conditions-generales-de-vente" element={<CGV />} />
                        <Route path="/politique-de-confidentialite" element={<Policies />} />
                        <Route path="/sitemap.xml" element={<Sitemap />} />
                        <Route path="/404-error" element={ <Error />} />
                        <Route path="*" element={ <Navigate to="/404-error" /> } />
                    </Routes>
                    <Footer />
                  </PageTitleProvider>
              </SurveyProvider>
            </ThemeProvider>
          </Router>
        </Suspense>
      </HelmetProvider>  
    </React.StrictMode>
  );
}
export default App;