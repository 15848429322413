import styled from 'styled-components'
import { StyledButton, StyledBar } from '../../../utils/style/Atoms'
import colors from '../../../utils/style/colors'
import { useTheme } from '../../../utils/hooks'
import React from 'react'
import { Link } from 'react-router-dom'

import backgroundImageDark from '../../../../src/assets/skills5.webp'
import backgroundImageLight from '../../../../src/assets/skills5.webp'

import { Helmet, HelmetProvider } from 'react-helmet-async'

const SecondSection = styled.section`
    margin: auto;
    padding: 20px 0;
    align-items: center;
    background-color: ${colors.backgroundLight};
    transition: all 200ms;
    z-index: -1;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    max-width: 2200px;
    gap: 10px;
    ${StyledBar} {
        margin-left: 0px;
        @media screen and (max-width: 1140px) {
            margin: 20px 0;
        }
        @media screen and (max-width: 980px) {
            margin: 20px auto;
        }
    }
    @media screen and (max-width: 980px) {
        display: block;
        text-align: center;
        ${StyledButton} {
            margin: 20px auto;
            @media screen and (max-width: 480px) {
                margin: 10px auto 20px;
            }
        }
    }
`

const SecondEntitySection = styled.section`
    display: flex;
    flex-direction: column;
    z-index: 1;
    transition: all 200ms;
    @media screen and (min-width: 1280px) {
        min-width: 645px;
    }
    @media screen and (min-width: 11400px) and (max-width: 1280px) {
        max-width: 60%;
        padding-right: 50px;
    }
    @media screen and (max-width: 980px) {
        display: block;
        width: 85%;
        margin: auto;
        text-align: center;
    }
`

const SecondEntityTitle = styled.h2`
    line-height: 1;
    margin-bottom: 8px;
    margin-left: 0px;
    color: ${colors.black};
    transition: all 200ms;
    @media screen and (max-width: 930px) {
        text-align: center;
    }
`

const SecondEntityText = styled.p`
    color: ${colors.black};
    transition: all 200ms;
    margin-top: 10px;
    text-align: justify;
    max-width: 420px;
    @media screen and (max-width: 980px) {
        margin: 20px auto 10px;
        max-width: 85%;
    }
`

const PictureSecondSection = styled.section`
    width: 600px;
    height: 500px;
    background: no-repeat center/100% url(${({ theme }) => (theme === 'light' ? backgroundImageLight : backgroundImageDark)});
    transition: all 200ms;
    background-position: 50% 50%;
    z-index: 1;
    display: flex;
    @media screen and (max-width: 1410px) {
        max-width: 480px;
    }
    @media screen and (max-width: 1280px) {
        max-width: 400px;
    }
    @media screen and (max-width: 980px) {
        max-width: 50%;
        max-height: 240px;
        margin: auto;
    }
    @media screen and (max-width: 680px) {
        max-width: 60%;
    }
    @media screen and (max-width: 590px) {
        max-width: 70%;
        max-height: 200px;
    }
    @media screen and (max-width: 380px) {
        max-height: 140px;
    }
`

const ButtonLink = styled(Link)`
    transition: all 200ms;
    width: fit-content;
    margin: 20px 0;
    @media screen and (max-width: 980px) {
        margin: 20px auto;
    }
`

function HomeExpertise() {
    const { theme } = useTheme()

    return (

        <HelmetProvider>
            <Helmet>
                <link rel='preload' as='image' href={`${backgroundImageLight}`} />
            </Helmet>
        
            <SecondSection>
                <SecondEntitySection>
                    <SecondEntityTitle>
                        EXPERTISE
                    </SecondEntityTitle>
                    <StyledBar theme={theme}></StyledBar>
                    <SecondEntityText>
                        Notre équipe est spécialisée dans le secteur du développement web et mobile, ainsi qu'en programmation informatique. <br/>
                        <br/>
                        A l'écoute de ses partenaires, Becom'in contribue au développement de votre activité professionnelle et à la croissance de votre entreprise, avec la création et l'optimisation de votre site internet, application web, ou appli mobile, de manière totalement personnalisée.
                    </SecondEntityText>

                    <ButtonLink to="/expert-conception-web-mobile-caen" href='#home' title="Découvrir nos compétences" >
                        <StyledButton theme={theme}>Découvrez nos spécialités</StyledButton>
                    </ButtonLink>
                </SecondEntitySection>
                <PictureSecondSection theme={theme} className={"card-1"} />
            </SecondSection>
        </HelmetProvider>
    )
}

export default HomeExpertise