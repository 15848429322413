import { Link } from 'react-router-dom'
import colors from './colors'
import styled, { keyframes } from 'styled-components'
import cadre1 from '../../../src/assets/cadre-orange.png'
import cadre2 from '../../../src/assets/cadre-jaune2.png'
import image from '../../../src/assets/internet1.jpg'



export const MediaApparition = keyframes`
  0% {
    background-color: white;
    background-position: 50% 50%;
    background-opacity: 0;
    background-repeat: no-repeat;
  }
  99% {
    background: url(${image});
    background-size: contain;
    background-position: 50% 50%;
    background-opacity: 0;
    background-repeat: no-repeat;
  }
  100% {
    background: url(${image});
    background-position: 50% 50%;
    background-size: contain;
    background-opacity: 1;
    background-repeat: no-repeat;
  }
`

export const LazyApparition = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  60% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
`


export const RollingStone = keyframes`
    0% {
        transform : translateX(1000px) rotate(600deg) scale(0.5);
    }
    20% { 
        transform : translateX(600px) rotate(480deg) scale(0.7);
    }
    40% {
        transform : translateX(400px) rotate(360deg) scale(0.85);
    }
    60% {
        transform : translateX(200px) rotate(240deg) scale(1);
    }
    80% {
        transform : translateX(100px) rotate(120deg) scale(1.8);
    }
    96% {
        transform: translateX(0px) scale(1.1);
    }
    98% {
        transform: translateX(-60px) scale(0.5);
    }
    100% {
        transform: translateX(-60px) rotate(0deg) scale(0);
    }
`


export const FixedRollingStone = keyframes`
    0% {
        transform : translateX(1000px) rotate(600deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    20% { 
        transform : translateX(600px) rotate(480deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    40% {
        transform : translateX(400px) rotate(360deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    60% {
        transform : translateX(200px) rotate(240deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    80% {
        transform : translateX(100px) rotate(120deg);
    }
    100% {
        transform: translateX(0px) rotate(0deg);
        border: none;
    }
`

export const LogoRollingStone = keyframes`
    0% {
        transform : translateX(1800px) rotate(720deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    10% {
        transform : translateX(1400px) rotate(600deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    20% { 
        transform : translateX(1000px) rotate(480deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    30% { 
        transform : translateX(800px) rotate(420deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    40% {
        transform : translateX(600px) rotate(360deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    50% {
        transform : translateX(400px) rotate(300deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    60% {
        transform : translateX(300px) rotate(240deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    70% {
        transform : translateX(200px) rotate(180deg);
        box-shadow: ${(theme) =>
            theme === 'light' ? `inset 1px 1px 1px ${colors.celadon}, inset -1px -1px 1px ${colors.celadon}, inset 4px 3px 4px ${colors.orange}, inset -4px -3px 4px ${colors.orange}` : `inset 1px 1px 1px ${colors.backgroundDark}, inset -1px -1px 1px ${colors.backgroundDark}, inset 4px 3px 4px ${colors.primary}, inset -4px -3px 4px ${colors.primary}`};
    }
    80% {
        transform : translateX(150px) rotate(120deg);
    }
    90% {
        transform : translateX(75px) rotate(60deg);
    }
    100% {
        transform: translateX(0px) rotate(0deg);
        border: none;
    }
`

export const DrawerNavigation = keyframes`
    0% {
        transform: translateX(250px);
    }
    20% {
        transform: translateX(200px);
    }
    40% {
        transform: translateX(150px);
    }
    60% {
        transform: translateX(100px);
    }
    80% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(0px);
    }
`


export const HamburgerRise = keyframes`
    0% {
        transform: translateX(-2000px);
    }
    10% {
        transform: translateX(-1500px);
    }
    20% {
        transform: translateX(-1000px);
    }
    30% {
        transform: translateX(-1000px);
    }
    40% {
        transform: translateX(-750px);
    }
    50% {
        transform: translateX(-500px);
    }
    60% {
        transform: translateX(-400px);
    }
    70% {
        transform: translateX(-300px);
    }
    80%{
        transform: translateX(-200px);
    }
    90%{
        transform: translateX(-100px);
    }
    100%{
        transform: translateX(0px);
    }
`


const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const Rotation = keyframes`
0% {
    transform : rotate(-600deg);
  }
  20% {
    transform : rotate(-480deg);
  }
  40% {
    transform: rotate(-360deg);
  }
  60% {
    transform: rotate(-240deg);
  }
  80% {
    transform: rotate(-120deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

export const Loader = styled.article`
    padding: 10px;
    border: 6px solid ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary)};
    border-bottom-color: transparent;
    border-radius: 22px;
    animation: ${rotate} 1s infinite linear;
    height: 0;
    width: 0;
`

export const StyledLink = styled(Link)`
    padding: 10px 20px;
    color: ${({ theme }) => (theme === 'light' ? '#8186a0' : '#ffffff')};
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    transition: all 200ms;
    width: fit-content;
    ${(props) =>
        props.$isFullLink &&
        `color: white; 
        border-radius: 30px; 
        background: linear-gradient(60deg, #05D6D9, #F907FC);`}
`

export const StyledButton = styled.button`
    padding: 5px 20px;
    background-color: ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary)};
    backface-visibility: hidden;
    font-size: 16px;
    font-weight: 550;
    text-align: center;
    border: none;
    transition: all 150ms;
    border-radius: 2px;
    box-shadow: 2px 2px 6px ${colors.secondary};
    @media screen and (max-width: 980px) {
        margin: auto;
    }
    ${(props) =>
        props.$isFullLink &&
        `color: white; 
        border-radius: 30px; 
        background: linear-gradient(60deg, #05D6D9, #F907FC);`}
    &:hover {
        transform: scale(1.047) translateZ(0);
        box-shadow: 4px 4px 6px ${colors.secondary};
    }
    @media (prefers-color-scheme: dark){
        box-shadow: 4px 4px 6px ${colors.secondary};
    }
`

export const StyledBar = styled.hr`
    width: 40px;
    border-top: 3px solid ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary)};
    opacity: 100%;
    z-index: 1;
    margin-top: 10px;
    transition: all 200ms;
    background: none;
    @media screen and (max-width: 980px) {
        margin: 10px auto;
    }
`

export const TopContainer = styled.section`
    background: none;
    transition: all 200ms;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 1950px;
    padding: 20px 25px;
    margin: auto;
    justify-content: space-around;
    transition: all 200ms;
    @media screen and (min-width: 980px) {
        flex-direction: row;
        padding: 10px 40px 20px;
        min-height: 420px;
    }
    @media screen and (min-width: 481px) and (max-width: 980px) {
        padding: 30px 40px 20px;
    }
`

export const TopTextSection = styled.section`
    max-width: 50%;
    display: flex;
    flex-direction: column;
    border: 6px solid transparent;
    padding: 30px 50px;
    border-image: ${({ theme }) => (theme === 'light' ? `url(${cadre1}) 10` : `url(${cadre2}) 10`)};
    transition: all 200ms;
    margin: auto 0;
    @media screen and (max-width: 980px) {
        max-width: 80%;
        padding: 30px 40px;
        margin: auto;
    @media screen and (max-width: 480px) {
        max-width: 100%;
        text-align: center;
        padding: 0px 25px;
        border-image: none;
    }
    @media screen and (min-width: 480px) and (max-width: 980px) {
        ${StyledBar} {
            margin-left: 0;
        }
    }
`

export const TopTitle = styled.h2`
    color: ${colors.black};
    transition: all 200ms;
`

export const TopPresentation = styled.p`
    margin: 10px 0 16px 0;
    color: ${colors.black};
    transition: all 200ms;
    text-align: justify;
    word-break: break-word;
    @media screen and (min-width : 980px) {
        max-width: 600px; 
    }
    
`

export const TopComplement = styled.p`
    color: ${colors.black};
    transition: all 200ms;
    text-align: justify;
    @media screen and (min-width : 980px) {
        max-width: 600px;
    }
    
`

export const TopImageSection = styled.section`
    max-width: 40%;
    display: flex;
    transition: all 200ms;
    padding: 20px;
    @media screen and (max-width: 980px) {
        margin: 30px auto;
        max-width: 100%;
    }
`

export const TopImage = styled.img`
    max-width: 450px;
    transition: all 200ms;
    @media screen and (max-width: 1280px) {
        max-width: 400px;
    }
    @media screen and (max-width: 980px) {
        max-width: 60%;
    }
`

export const SkipNavLinks = styled.a`
    background-color: ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary)};
    color: ${colors.black};
    font-weight: 700;
    left: 50%;
    padding: 4px;
    position: absolute;
    transition: transform 0.3s;
    transform: translateY(-100%) ;
    &:focus {
        transform: translateY(0%);
        box-shadow: 2px 2px 6px ${colors.secondary};
        border: none;
        top: 10px;
    }
    @media screen and (max-width: 480px) {
        display: none;
    }

`